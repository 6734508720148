/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { TextField } from '@mui/material/';
import InputAdornment from '@mui/material/InputAdornment';
import { Control, Controller } from 'react-hook-form';

/**
 * returns prefix, suffix to input props for text component.
 *
 * @param data the current value
 * @param prefix the string value of prefix
 * @param postfix the string value of suffix
 * @param focused if the component is focused
 */
const buildPrefixSuffix = (data: any, prefix: React.ReactNode, postfix: React.ReactNode, focused: boolean) => {
  let prefixPostfix = {};

  if (prefix !== undefined && (focused || data)) {
    prefixPostfix = {
      ...prefixPostfix,
      startAdornment: <InputAdornment position="start">{prefix || <></>}</InputAdornment>,
    };
  }
  if (postfix !== undefined) {
    prefixPostfix = {
      ...prefixPostfix,
      endAdornment: <InputAdornment position="end">{postfix || <></>}</InputAdornment>,
    };
  }

  return prefixPostfix;
};

interface TextComponentProps {
  name: string;
  control: Control<any, any>;
  rules?: {
    [x: string]: any;
  };
  type?: 'text' | 'number' | 'email' | 'password';
  placeholder?: string;
  style?: React.CSSProperties;
  variant?: 'outlined' | 'filled' | 'standard';
  label: string;
  helperText?: string;
  prefix?: React.ReactNode;
  postfix?: React.ReactNode;
  disabled?: boolean;
  small?: boolean;
  rows?: number;
}

/**
 * Renders a full-width text component
 *
 * required props:
 *  - name : variable name
 *  - control : control object from useForm
 *  - label : label of the input
 *
 * options:
 *  - rules : object of rules for RHF
 *  - prefix : renders a string before the input if it exists
 *  - postfix : renders a helper mark with the string provided when hovering
 *  - disabled : disabled or not
 *  - style : styling of component
 *  - helperText : a text under the input to be displayed permanent or not
 *  - small : if the text input should be small
 */
const TextComponent: React.FC<TextComponentProps> = ({
  name,
  control,
  rules = {},
  type = 'text',
  placeholder = '',
  style = null,
  variant = 'outlined',
  label,
  helperText = null,
  prefix = null,
  postfix = null,
  disabled = false,
  small = false,
  rows = 1,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules as any}
      render={({ field: { value, ...rest }, fieldState: { error } }) => (
        <TextField
          {...rest}
          fullWidth
          type={type || 'text'}
          placeholder={placeholder}
          variant={variant || 'outlined'}
          value={value || ''}
          label={label}
          multiline={rows > 1}
          minRows={rows}
          maxRows={rows}
          error={!!error}
          helperText={error ? error.message : helperText || ''}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          style={style || { marginBottom: '16px', marginTop: '16px' }}
          color="secondary"
          InputProps={buildPrefixSuffix(value, prefix, postfix, focused)}
          disabled={disabled || false}
          inputProps={small ? { style: { fontSize: '14px' }, 'aria-label': label } : { 'aria-label': label }}
        />
      )}
    />
  );
};

export default TextComponent;
